import { createSlice } from '@reduxjs/toolkit';

const LayerDataSlice = createSlice({
  name: 'layerData',
  initialState: {
    webAnalyticsPlacements: [],
    webAnalyticsPages: [],
    timeSeriesGeom: [],
    priorVisitsAnalysisAggregated: [],
    currentVisitsAnalysisAggregated: [],
    loadingPercentage: 0,
    isLoadingLayers: false,
    poiData: [],
    demographics: [],
    proximityAnalysis: []
  },
  reducers: {
    setWebAnalyticsPlacements: (state, action) => {
      const currentSet = new Set(state.webAnalyticsPlacements);
      action.payload.forEach(item => currentSet.add(item));
      state.webAnalyticsPlacements = Array.from(currentSet);
    },
    setWebAnalyticsPages: (state, action) => {
      const currentSet = new Set(state.webAnalyticsPages);
      action.payload.forEach(item => currentSet.add(item));
      state.webAnalyticsPages = Array.from(currentSet);
    },
    setTimeSeriesGeom: (state, action) => {
      state.timeSeriesGeom = action.payload
    },
    setPriorVisitsAnalysisAggregated: (state,action) => {
      state.priorVisitsAnalysisAggregated = action.payload;
    },
    setCurrentVisitsAnalysisAggregated: (state,action) => {
      state.currentVisitsAnalysisAggregated = action.payload
    },
    setLoadingPercentage: (state,action) => {
      state.loadingPercentage = action.payload;
    },
    setIsLoadingLayers: (state, action) => {
      state.isLoadingLayers = action.payload;
    },
    setPOIData: (state, action) => {
      state.poiData = action.payload;
    },
    setDemographics: (state, action) => {
      state.demographics = action.payload;
    },
    setProximityAnalysis: (state, action) => {
      state.proximityAnalysis = action.payload;
    }
  }
});

export default LayerDataSlice.reducer;
export const { 
  setWebAnalyticsPlacements, 
  setWebAnalyticsPages,
  setTimeSeriesGeom, setLoadingPercentage, setIsLoadingLayers, 
  setPriorVisitsAnalysisAggregated, 
  setCurrentVisitsAnalysisAggregated,
  setPOIData,
  setDemographics,
  setProximityAnalysis
} = LayerDataSlice.actions;
